import React, {memo, useEffect, useState, useRef} from 'react';
import moment from "moment";
import {Bar} from 'react-chartjs-2';
import ChartTooltip from "./ChartTooltip";
import {useStyles} from './style.js';
import {useRouteMatch} from "react-router-dom";
import {ROUTES} from "../../constans";

const CardGraph = ({ price, circulation, dates, columns, showToolTip }) => {
  const classes = useStyles();
  const [cardData, setCardData] = useState({
    labels: [],
    datasets: [],
    dates: []
  });
  const toolTipRef = useRef(null);
  const [currentCaretPos, setCurrentCaretPos] = useState();
  const [dataDates, setDataDates] = useState([]);
  const [toolTipTitle, setToolTipTitle] = useState("");
  const [toolTipPrice, setToolTipPrice] = useState("");
  const [toolTipCirculation, setToolTipCirculation] = useState("");
  const [toolTipDisplayed, setToolTipDisplayed] = useState(false);
  const [arrowPosition, setArrowPosition] = useState("center");
  const whiteToolTipBg = useRouteMatch(ROUTES.DASHBOARD);

  const tooltipFn = function(context) {
      if (!context.tooltip.dataPoints || context.tooltip.dataPoints.length === 0) {
        return;
      }
      const dataIndex = context.tooltip.dataPoints[0].dataIndex;
      const displayDate = moment(dataDates[dataIndex])
        .utc()
        .format('MMMM YYYY');
      setToolTipTitle(displayDate);
      setToolTipPrice(`$${ parseFloat(cardData.datasets[1].data[dataIndex].toPrecision(5).toString())}`);
      setToolTipCirculation(`${cardData.datasets[0].data[dataIndex].toFixed(1)}`);
      const canvasBounds = context.chart.canvas.getBoundingClientRect();
      const caretX = context.tooltip.caretX;
      const caretY = context.tooltip.caretY;
      const tooltipEl = toolTipRef.current;
      let styleX = caretX - tooltipEl.offsetWidth/2 + 3;
      const styleY = caretY - tooltipEl.offsetHeight - 15;
      const tooltipRight = styleX + tooltipEl.offsetWidth + canvasBounds.left;
      const tooltipLeft = styleX + canvasBounds.left;
      setArrowPosition("center");
      if (tooltipRight > canvasBounds.right) {
        styleX = caretX - tooltipEl.offsetWidth + 15;
        setArrowPosition("right");
      } else if (tooltipLeft < canvasBounds.left) {
        styleX = caretX - 12;
        setArrowPosition("left");
      }
      // Hide if no tooltip
      let display = "";
      if (context.tooltip.opacity === 0) {
          display = "none";
      }
      if (caretX !== currentCaretPos || context.tooltip.opacity === 0) {
          tooltipEl.style.display = display;
          tooltipEl.style.left = `${styleX}px`;
          tooltipEl.style.top = `${styleY}px`;
      }
      setCurrentCaretPos(caretX);
  }

  const cityGraph = () => {
    const labelConstant = 10;
    let labelValue = 0;
    const labels = [];
    let states = [];
    let equityValue = [];

    let idx = 0;
    if (columns) {
      while (idx < columns) {
        labelValue += labelConstant;
        labels.push(labelValue);
        states.push(0);
        equityValue.push(0);
        idx += 1;
      }
    } else {
      while(idx < price.length) {
        labels.push(labelValue);
        labelValue += labelValue;
        idx += 1;
      }
    }
    if (columns) {
      const start = price.length - columns;
      states = circulation.slice(start, price.length);
      equityValue = price.slice(start, price.length);
      if (states.length < labels.length) {
        states = new Array(labels.length - states.length).fill(0).concat(states);
        equityValue = new Array(labels.length - price.length).fill(0).concat(price);
        setDataDates(new Array(labels.length - dates.length).fill("").concat(dates));
      }
    } else {
      states = [...circulation];
      equityValue = [...price];
      setDataDates([...dates]);
    }

    setCardData({
      labels,
      datasets: [
        {
          type: 'bar',
          label: 'Hauscoin issued',
          data: states,
          backgroundColor: ['rgba(70, 138, 255, .25)'],
          hoverBackgroundColor: ['rgba(70, 138, 255, 0.50)'],
          order: 1,
          barPercentage: 0.98,
          categoryPercentage: 1.0,
        },
        {
          type: 'line',
          label: 'Equity price',
          data: equityValue,
          backgroundColor: ['rgba(70, 138, 255, 100)'],
          borderColor: ['rgba(70, 138, 255, 100)'],
          order: 0,
          pointRadius: 0,
          borderWidth: 2,
          yAxisID: 'y-axis',
          xAxisID: 'x-axis',
        },
      ],
    });
  };

  useEffect(() => {
    cityGraph();
  }, [price, circulation]);

  return (
    <div className={classes.cardGraphContainer}>
      <Bar data={cardData}
           options={
             {
               plugins: {
                 legend: {
                   display: false
                 },
                 tooltip: {
                   external: showToolTip ? tooltipFn : undefined,
                   enabled: false,
                 },
               },
               responsive: true,
               maintainAspectRatio: false,
               scales: {
                 x: {
                   ticks: {
                     display: false
                   },
                   grid: {
                     display: false,
                     borderWidth: 3,
                     borderColor: 'transparent',
                   },
                 },
                 y: {
                   ticks: {
                     display: false
                   },
                   grid: {
                     display: false,
                     borderWidth: 3,
                     borderColor: 'transparent',
                   },
                 },
                 'y-axis': {
                   display: false,
                   beginAtZero: false,
                   ticks: {
                     display: false,
                   },
                   grid: {
                     display: false,
                   },
                 },
                 'x-axis': {
                   display: false,
                   grid: {
                     display: false,
                     borderWidth: 4,
                     borderColor: 'rgba(151, 151, 151, 100)'
                   },
                   ticks: {
                     display: false
                   }
                 }
               },
             }
           }
      />
      <ChartTooltip
        title={toolTipTitle}
        price={toolTipPrice}
        circulation={toolTipCirculation}
        tooltipRef={toolTipRef}
        arrowPosition={arrowPosition}
        toolTipClass={whiteToolTipBg ? classes.chartToolTipWhite : null}
      />
    </div>
  );
};

export default memo(CardGraph);
