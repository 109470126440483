import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  cardPage: {
    backgroundColor: theme.palette.bgColor.grey,
    padding: '154px 0',
  },
  cardPageWrapper: {
    position: 'relative',
    padding: '30px 30px 60px',
    backgroundColor: theme.palette.bgColor.white,
    borderRadius: '18px',
  },
  cardPageCrossImg: {
    position: 'absolute',
    top: '30px',
    right: '30px',
    cursor: 'pointer',
  },
  cardPageGraphWrapper: {
    height: '370px',
    position: 'relative',
    marginTop: '75px',
  },
  cardPageTitle: {
    width: 'fit-content',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  },
  cardPageTitleImgWrapper: {
    position: 'relative',
    width: '120px',
    height: '120px',
    backgroundColor: theme.palette.bgColor.lightBlue,
    borderRadius: '50%',
  },
  cardPageTitleImg: {
    width: '65%',
    height: '65%',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  cardPageTitleTextWrapper: {
    display: 'flex',
  },
  cardPageTitleWrapper: {
    position: 'relative',
    display: 'flex',
  },
  cardPageTitleText: {
    marginLeft: '20px',
    fontFamily: theme.typography.fontFamily.medium,
    fontSize: theme.fontSize.text.subHeader,
    lineHeight: '24px',
  },
  cardPageTagStyle: {
    position: 'unset',
    marginLeft: '18px',
  },
  cardPageTagStyleMob: {
    display: 'none',
    position: 'unset',
    marginTop: '5px',
  },
  cardPageDescriptionMob: {
    display: 'none',
    marginTop: '10px',
    fontFamily: theme.typography.fontFamily.medium,
    fontSize: theme.fontSize.text.medium,
    lineHeight: '22px',
    letterSpacing: '-0.2475px',
  },
  cardPageTitleTextSpan: {
    display: 'block',
    lineHeight: '24px',
  },
  cardPageDescription: {
    marginTop: '11px',
    fontFamily: theme.typography.fontFamily.medium,
    fontSize: theme.fontSize.text.medium,
    lineHeight: '22px',
    letterSpacing: '-0.2475px',
  },
  cardPageHeaderInfoWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '30px',
  },
  cardPageTokenValue: {
    display: 'flex',
    fontFamily: theme.typography.fontFamily.black,
    textTransform: 'uppercase',
  },
  cardPageHeaderTokenTitle: {
    fontSize: theme.fontSize.text.small,
    lineHeight: '14px',
    letterSpacing: '1.2px',
  },
  cardPageHeaderCirculationTitle: {
    marginLeft: '199px',
    fontSize: theme.fontSize.text.small,
    lineHeight: '14px',
    letterSpacing: '1.2px',
  },
  cardPageHeaderTokenTitleValue: {
    marginTop: '9px',
    display: 'block',
    fontSize: theme.fontSize.text.main,
    lineHeight: '60px',
    letterSpacing: '-1.17px',
    color: theme.palette.text.lightBlue,
  },
  cardPageHeaderBigTitle: {
    marginTop: '7px',
    display: 'block',
    fontSize: theme.fontSize.text.main,
    lineHeight: '60px',
    letterSpacing: '-1.17px',
  },
  cardPageHeaderSelect: {
    visibility: 'hidden',
    display: 'flex',
    alignItems: 'center',
  },
  cardPageHeaderSelectImg: {
    marginLeft: '71px',
  },
  cardPageCardInfo: {
    display: 'flex',
    flexFlow: 'row wrap',
    marginTop: '166px',
  },
  cardPageCardInfoTitle: {
    fontSize: theme.fontSize.text.small,
    lineHeight: '14px',
    letterSpacing: '1.2px',
    textTransform: 'uppercase',
    '&:nth-child(2)': {
      margin: '0 174px',
    }
  },
  cardPageCardInfoBigTitle: {
    marginTop: '12px',
    display: 'block',
    fontSize: theme.fontSize.text.main,
    lineHeight: '60px',
    letterSpacing: '-1.17px',
  },
  cardPageGraphBtnWrapper: {
    maxHeight: '56px',
    display: 'flex',
    position: 'absolute',
    bottom: '-75px',
  },
  cardBtn: {
    display: 'block',
    boxSizing: "border-box",
    width: '174px',
    textTransform: 'uppercase',
    textAlign: 'center',
    backgroundColor: theme.palette.bgColor.orange,
    outline: 'none',
    padding: '20px 60px',
    color: theme.palette.text.white,
    fontSize: theme.fontSize.text.small,
    cursor: 'pointer',
    letterSpacing: '1px',
  },
  cardTooltip: {
    maxWidth: '190px',
  },
  dashboardGraphBottomTitles: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '10px 10px 0 0',
  },
  dashboardGraphBottomTitle: {
    color: theme.palette.text.main,
    fontSize: theme.fontSize.text.small,
    margin: '0 0 0 20px',
    display: 'flex',
    alignItems: 'center',
    textTransform: 'uppercase',
    '&:nth-child(1)': {
      '& span': {
        height: '2px',
        width: '30px',
        marginRight: '10px',
        display: 'inline-block',
        backgroundColor: '#468AFF'
      }
    },
    '&:nth-child(2)': {
      '& span': {
        height: '6px',
        width: '30px',
        marginRight: '10px',
        display: 'inline-block',
        backgroundColor: 'rgba(70, 138, 255, 0.25)'
      }
    }
  },
  tooltipLink: {
    textDecoration: "underline",
    color: theme.palette.bgColor.white,
  },
  '@media (max-width: 1024px)': {
    cardPageCardInfoTitle: {
      '&:nth-child(2)': {
        margin: '0 45px',
      }
    },
  },
  '@media (max-width: 768px)': {
    cardPageHeaderInfoWrapper: {
      flexFlow: 'column',
      alignItems: 'flex-start',
    },
    cardPageHeaderSelect: {
      width: '100%',
      padding: '16px 0',
      margin: '20px auto 0',
    },

    cardPageTitleWrapper: {
      display: 'block',
    },
    cardPageFullTitle: {
      display: 'block',
    },
    cardPageHeaderSelectImg: {
      marginLeft: 'auto',
    },
    cardPageDescription: {
      display: 'none',
    },
    cardPageTagStyle: {
      display: 'none',
    },
    cardPageTagStyleMob: {
      display: 'block',
      width: 'fit-content',
    },
    cardPageDescriptionMob: {
      marginTop: '15px',
      display: 'block',
    },
    cardPageTokenValue: {
      flexFlow: 'column',
    },
    cardPageHeaderCirculationTitle: {
      margin: '20px 0',
    },
    cardPageGraphBtnWrapper: {
      width: '100%',
      flexFlow: 'column',
      bottom: '-107px',
    },
    cardBtn: {
      width: '100%',
    },
    cardPageCardInfoTitle: {
      '&:nth-child(2)': {
        margin: '20px 0 0 0',
      },
      '&:nth-child(3)': {
        marginTop: '20px',
      },
    },
    cardPageCardInfo: {
      justifyContent: 'space-between',
      marginTop: '255px',
    },
  },
  '@media (max-width: 480px)': {
    cardPage: {
      padding: '0',
      backgroundColor: theme.palette.bgColor.white,
    },
    cardPageWrapper: {
      padding: '130px 0 60px',
      overflow: 'hidden',
    },
    cardPageCrossImg: {
      top: '80px',
      right: '0',
    },
    cardPageTitleImgWrapper: {
      width: '100px',
      height: '100px',
    },
    cardPageTitleText: {
      fontSize: '21px',
    },
    cardPageHeaderTokenTitleValue: {
      fontSize: '42px',
    },
    cardPageGraphWrapper: {
      height: '250px',
      marginTop: '30px',
    },
    cardPageHeaderBigTitle: {
      fontSize: '42px',
    },
    cardPageHeaderCirculationTitle: {
      marginBottom: '0',
    },
    cardPageCardInfoBigTitle: {
      fontSize: '40px',
      lineHeight: '47px',
      letterSpacing: '-1.5px',
    },
    cardPageCardInfoTitle: {
      '&:nth-child(1)': {
        maxWidth: '160px',
      },
      '&:nth-child(2)': {
        maxWidth: '160px',
        margin: '0',
      },
    },
    dashboardGraphBottomTitle: {
      fontSize: '10px',
      letterSpacing: '0.5px',
    },
  },
  '@media (max-width: 380px)': {
    dashboardGraphBottomTitle: {
      margin: '8px 0 0 20px'
    },
  },
  '@media (max-width: 320px)': {
    cardPageCardInfoTitle: {
      '&:nth-child(2)': {
        margin: '20px 0',
      },
      '&:nth-child(3)': {
        marginTop: '0',
      },
    },
  }
}))
