import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  homePageContainer: {
    height: '100%',
    position: 'relative',
    padding: '0 20px'
  },

  homePageGraphWrapper: {
    height: '370px',
    position: 'relative',
    margin: '30px auto 156px',
    maxWidth: '800px'
  },
  cardPageHeaderInfoWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '30px auto 0',
    maxWidth: '800px'
  },
  cardPageTokenValue: {
    display: 'flex',
    fontFamily: theme.typography.fontFamily.black,
    textTransform: 'uppercase',
  },
  cardPageHeaderTokenTitle: {
    fontSize: theme.fontSize.text.small,
    lineHeight: '14px',
    letterSpacing: '1.2px',
  },
  cardPageHeaderCirculationTitle: {
    marginLeft: '199px',
    fontSize: theme.fontSize.text.small,
    lineHeight: '14px',
    letterSpacing: '1.2px',
  },
  cardPageHeaderTokenTitleValue: {
    marginTop: '9px',
    display: 'block',
    fontSize: theme.fontSize.text.main,
    lineHeight: '60px',
    letterSpacing: '-1.17px',
    color: theme.palette.text.lightBlue,
  },
  cardPageHeaderBigTitle: {
    marginTop: '7px',
    display: 'block',
    fontSize: theme.fontSize.text.main,
    lineHeight: '60px',
    letterSpacing: '-1.17px',
  },
  dashboardGraphBottomTitles: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '10px 10px 0 0',
  },
  dashboardGraphBottomTitle: {
    color: theme.palette.text.main,
    fontSize: theme.fontSize.text.small,
    margin: '0 0 0 20px',
    display: 'flex',
    alignItems: 'center',
    textTransform: 'uppercase',
    '&:nth-child(1)': {
      '& span': {
        height: '2px',
        width: '30px',
        marginRight: '10px',
        display: 'inline-block',
        backgroundColor: '#468AFF'
      }
    },
    '&:nth-child(2)': {
      '& span': {
        height: '6px',
        width: '30px',
        marginRight: '10px',
        display: 'inline-block',
        backgroundColor: 'rgba(70, 138, 255, 0.25)'
      }
    }
  },
  ledger: {
    backgroundColor: theme.palette.bgColor.grey,
    padding: '130px 73px 73px',
    textAlign: 'center',
    fontFamily: theme.typography.fontFamily.black
  },
  ledgerTitleWrapper: {
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
  },
  ledgerTitle: {
    marginTop: 0,
    marginBottom: '42px',
    fontSize: theme.fontSize.text.main,
    lineHeight: '60px',
    letterSpacing: '-2px'
  },
  coinButtonsWrapper: {
    display: 'flex',
    position: 'absolute',
    right: '0',
    top: '50%',
    transform: 'translateY(-100%)',
    flexDirection: 'column',
    alignItems: 'center',
  },
  ledgerInfo: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  ledgerInfoValueTitle: {
    fontSize: theme.fontSize.text.semiMain,
    lineHeight: '52px',
    letterSpacing: '-1.5px',
    marginBottom: '32px',
    marginTop: 0
  },
  ledgerInfoValueText: {
    marginTop: 0,
    marginBottom: '81px',
    fontSize: theme.fontSize.text.bigHeader,
    textAlign: 'center',
    lineHeight: '52px',
    letterSpacing: '-1.5px',
    color: theme.palette.text.grey
  },
  ledgerBtnWrapper: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  ledgerBtn: {
    display: 'inline-flex',
    padding: '20px 28px',
    marginTop: '60px',
    marginRight: '70px',
    fontSize: theme.fontSize.text.small,
    lineHeight: '16px',
    letterSpacing: '1.2px',
    color: theme.palette.text.main,
    border: '2px solid black',
    outline: 'none',
    cursor: 'pointer',
  },
  content: {
    backgroundColor: theme.palette.bgColor.white,
    paddingTop: '104px',
    paddingBottom: '165px',
    fontFamily: theme.typography.fontFamily.black
  },
  contentTitle: {
    width: '413px',
    textAlign: 'center',
    fontSize: theme.fontSize.text.big,
    lineHeight: '80px',
    letterSpacing: '-2px',
    margin: '0 auto',
    paddingBottom: '42px'
  },
  contentArticle: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '120px',
    '&:nth-child(odd)': {
      flexDirection: 'row-reverse',
      textAlign: 'right'
    }
  },
  contentArticleTextWrapper: {
    marginLeft: '-70px'
  },
  alignRight: {
    marginRight: '-50px'
  },
  contentArticleTitle: {
    fontSize: theme.fontSize.text.main,
    lineHeight: '60px',
    letterSpacing: '-1.5px',
    marginTop: 0,
    marginBottom: '30px'
  },
  contentArticleText: {
    fontFamily: theme.typography.fontFamily.medium,
    fontSize: theme.fontSize.text.semiTotal,
    lineHeight: '22px',
    letterSpacing: '-0.25px',
    marginTop: 0,
    '&:nth-child(odd)': {
      textAlign: 'right'
    }
  },
  landingGraph: {
    padding: '120px 0 422px',
    backgroundColor: theme.palette.bgColor.grey,
    position: 'relative',
  },
  landingGraphLabel: {
    margin: '0 auto',
  },
  landingGraphBigTitle: {
    maxWidth: '826px',
    margin: '30px auto 66px',
    letterSpacing: '-2px',
    textAlign: 'center',
  },
  landingGraphText: {
    maxWidth: '378px',
    margin: '0 auto 60px',
    fontFamily: theme.typography.fontFamily.medium,
    fontSize: theme.fontSize.text.semiTotal,
    lineHeight: '22px',
    letterSpacing: '-0.2475px',
    textAlign: 'center'
  },
  landingGraphImg: {
    width: '100%',
  },
  landingGraphCardsBlock: {
    display: 'flex',
    position: 'absolute',
    bottom: '-58px',
    left: '50%',
    transform: 'translateX(-50%)',
  },
  more: {
    padding: '178px 0 98px',
    backgroundColor: theme.palette.bgColor.orange,
  },
  moreContentWrapper: {
    padding: '0 40px',
    display: 'flex',
  },
  moreInfoWrapper: {
    marginRight: '116px'
  },
  moreLogoWrapper: {
    display: 'flex',
  },
  moreLogoImg: {
    width: '25px',
    height: '32px',
  },
  moreLogoTitle: {
      marginLeft: '8px'
  },
  moreLogoTextWrapper: {
    maxWidth: '276px',
    marginTop: '58px',
  },
  moreLogoTextSmall: {
    marginTop: '30px',
    fontFamily: theme.typography.fontFamily.medium,
    fontSize: theme.fontSize.text.semiTotal,
    lineHeight: '22px',
    letterSpacing: '-0.2475px',
  },
  investors: {
    backgroundColor: theme.palette.bgColor.white,
    padding: '168px 0 0'
  },
  investorsLabel: {
    marginBottom: '20px'
  },
  investorsTitle: {
    maxWidth: '568px',
    margin: '0 auto 110px',
    textAlign: 'center',
  },
  investorsLogos: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  investorsLogosImg: {
    width: '20%',
    marginTop: '0px',
    marginBottom: '108px',
    marginLeft: '30px',
    marginRight: '30px',
  },
  sliderSection: {
    position: 'relative',
    padding: '80px 0 161px',
  },
  sliderSectionImg: {
    position: 'absolute',
    bottom: '0',
    right: '0',
  },
  sliderSectionLogo: {
    marginBottom: '97px'
  },
  homePageSliderText: {
    marginTop: '38px',
    marginBottom: '52px',
  },
  homePageBottomSlider: {
    position: 'relative',
    backgroundColor: theme.palette.bgColor.grey,
    padding: '79px 20px 194px',
    fontFamily: theme.typography.fontFamily.black
  },
  homePageBottomSliderText: {
    maxWidth: '918px',
    marginTop: '30px',
    marginBottom: '30px',
    color: theme.palette.text.orange,
  },
  homePageSliderHouses: {
    position: 'absolute',
    right: '0',
    bottom: '0',
  },
  '@media (max-width: 1280px)': {
    homePageContainer: {
      padding: '0 20px'
    },
    ledgerTitleWrapper : {
      display: 'block'
    },
    coinButtonsWrapper : {
      position: 'static',
      transform: 'translateY(-50%)',
    },
    ledger: {
      padding: '100px 20px',
    },
    ledgerInfo: {
      flexDirection: 'column',
      alignItems: 'center'
    },
  },

  '@media (max-width: 1080px)': {
    landingGraph: {
      padding: '120px 0 1170px',
    },
    landingGraphCardsBlock: {
      flexFlow: 'column',
      bottom: '-58px',
      left: '50%',
      transform: 'translateX(-50%)',
    },
  },
  '@media (max-width: 1024px)': {
    moreContentWrapper: {
      flexFlow: 'column',
      alignItems: 'center',
      padding: '0',
    },
    moreCadrImg: {
      marginTop: '41px',
    },
    contentArticleTitle: {
      fontSize: '38px'
    },
    contentArticleText: {
      fontSize: '12px'
    }
  },
  '@media (max-width: 960px)': {
    contentTitle: {
      fontSize: '60px',
      paddingBottom: '0'
    },
    contentArticleImg: {
      width: '90%'
    },
    contentArticleTextWrapper: {
      marginLeft: '-75px'
    },
    contentArticleTitle: {
      fontSize: '30px',
      lineHeight: '35px'
    },
    contentArticleText: {
      lineHeight: '15px'
    },
    alignRight: {
      marginRight: '-95px'
    }
  },
  '@media (max-width: 600px)': {
    ledgerInfoValueImg: {
      width: '100%'
    },
    contentArticle: {
      flexDirection: 'column',
      textAlign: 'center',
      '&:nth-child(odd)': {
        flexDirection: 'column',
        textAlign: 'center'
      }
    },
    contentArticleTextWrapper: {
      marginLeft: 0
    },
    alignRight: {
      marginRight: 0
    },
    contentArticleTitle: {
      margin: '20px 0'
    },
    contentArticleText: {
      width: '80%',
      margin: '0 auto'
    }
  },
  '@media (max-width: 480px)': {
    ledger: {
      padding: '0 20px 72px 20px'
    },
    coinButtonsWrapper : {
      transform: 'translateY(50%)',
    },
    ledgerTitle: {
      display: 'none',
      fontSize: '35px',
      marginBottom: '50px'
    },
    ledgerInfoValueTitle: {
      fontSize: '48px',
      margin: '67px 0 30px'
    },
    ledgerInfoValueText: {
      marginBottom: '58px',
      fontSize: '26px'
    },
    ledgerBtn: {
      margin: '70px auto 0'
    },
    content: {
      paddingTop: '54px',
      paddingBottom: '54px'
    },
    contentArticle: {
      marginTop: '67px'
    },
    contentTitle: {
      fontSize: '40px',
      width: '100%',
    },
    landingGraph: {
      padding: '120px 0 1097px',
    },
    more: {
      padding: '145px 0 139px',
    },
    moreInfoWrapper: {
      marginRight: '60px'
    },
    investors: {
      padding: '80px 0 120px',
      marginBottom: 0
    },
    investorsTitle: {
      marginBottom: '74px',
      fontSize: '40px',
      lineHeight: '40px',
      letterSpacing: '-0.468px',
    },
    investorsLogosImg: {
      width: '30%',
      marginBottom: '32px',
    },
    sliderSection: {
      padding: '80px 0 186px',
    },
    sliderSectionLogo: {
      marginBottom: '60px',
    },
    sliderSectionImg: {
      width: '107px',
      height: '92px',
    },
    homePageSliderText: {
      marginTop: '18px',
      marginBottom: '48px',
    },
    homePageBottomSlider: {
      padding: '79px 0 205px',
    },
    homePageBottomSliderText: {
      marginTop: '18px',
      marginBottom: '48px',
    },
  },
  '@media (max-width: 380px)': {
    contentTitle: {
      lineHeight: '50px'
    }
  },
  // '@media (max-width: 320px)': {
  //     header : {
  //         height: '100%'
  //     },
  //     headerTitle : {
  //         fontSize: '30px',
  //         lineHeight: '40px'
  //     },
  //     headerText : {
  //         fontSize: '12px'
  //     },
  //     headerBtn : {
  //         marginBottom: '100px'
  //     },
  //     headerImg : {
  //         width: '60%',
  //         marginRighgt: '10px'
  //     },
  //     ledgerInfoValueImg : {
  //         width: '80%'
  //     },
  //     ledgerBtn : {
  //         margin: '50px auto',
  //         padding: '15px'
  //     },
  //     contentArticle : {
  //         marginTop: '40px'
  //     },
  //     contentTitle : {
  //         fontSize: '30px',
  //         width: '200px'
  //     },
  //     investorsTitle : {
  //         fontSize: '30px'
  //     }
  // }
}))
